var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('section',{attrs:{"id":"dashboard-ecommerce"}},[(!_vm.isCashier)?_c('b-row',{staticClass:"match-height"},[_c('b-col',[_c('ecommerce-statistics',{attrs:{"data":_vm.statisticsItems}})],1)],1):_vm._e(),_c('b-row',[_c('b-col',{attrs:{"xl":"6","md":"6"}},[_c('b-card',{staticClass:"mb-2"},[_c('b-form',{ref:"products"},[_c('b-row',[_c('b-col',[_c('b-row',[_c('b-col',{attrs:{"xl":"6","md":"12"}},[_c('b-form-group',{attrs:{"label":"Fecha inicial"}},[_c('b-form-datepicker',{attrs:{"locale":"es","hide-header":true,"placeholder":"","name":"from","min":_vm.minDate,"max":_vm.maxDate},model:{value:(_vm.start_date),callback:function ($$v) {_vm.start_date=$$v},expression:"start_date"}})],1)],1),_c('b-col',[_c('b-form-group',{attrs:{"label":"Fecha final"}},[_c('b-form-datepicker',{attrs:{"locale":"es","hide-header":true,"placeholder":"","name":"from","min":_vm.minDate,"max":_vm.maxDate},model:{value:(_vm.end_date),callback:function ($$v) {_vm.end_date=$$v},expression:"end_date"}})],1)],1)],1),_c('b-form-group',{attrs:{"label":"Colegios"}},[_c('v-select',{staticClass:"mx-select",attrs:{"options":_vm.schoolOptions,"reduce":function (item) { return item.id; },"placeholder":"Colegios","label":"name","clearable":false},model:{value:(_vm.school_id_pfrom),callback:function ($$v) {_vm.school_id_pfrom=$$v},expression:"school_id_pfrom"}})],1)],1)],1),_c('b-card-body',{staticClass:"statistics-body"},[_c('b-row',[_c('b-media',{attrs:{"no-body":""}},[_c('b-media-aside',{staticClass:"mr-2"},[_c('b-avatar',{attrs:{"size":"48","variant":'light-danger'}},[_c('feather-icon',{attrs:{"size":"24","icon":"TrendingUpIcon"}})],1)],1),_c('b-media-body',{staticClass:"my-auto"},[_c('h3',[_vm._v("Productos")])])],1),_c('b-col',{staticClass:"d-flex justify-content-center"},[_c('b-button',{staticClass:"mr-1 f11",attrs:{"type":"button","variant":"primary"},on:{"click":function($event){return _vm.action(
                      'products',
                      'pdf',
                      _vm.start_date,
                      _vm.end_date,
                      _vm.school_id_pfrom
                    )}}},[_vm._v(" PDF ")]),_c('b-button',{staticClass:"mr-1 f11",attrs:{"type":"button","variant":"outline-primary"},on:{"click":function($event){return _vm.action(
                      'products',
                      'xlsx',
                      _vm.start_date,
                      _vm.end_date,
                      _vm.school_id_pfrom
                    )}}},[_vm._v(" EXCEL ")])],1)],1)],1)],1)],1)],1),_c('b-col',{attrs:{"xl":"6","md":"6"}},[_c('b-card',{staticClass:"mb-2"},[_c('b-form',{ref:"products"},[_c('b-row',[_c('b-col',[_c('b-row',[_c('b-col',{attrs:{"xl":"6","md":"12"}},[_c('b-form-group',{attrs:{"label":"Fecha inicial"}},[_c('b-form-datepicker',{attrs:{"locale":"es","hide-header":true,"placeholder":"","name":"from","min":_vm.minDate,"max":_vm.maxDate},model:{value:(_vm.start_date),callback:function ($$v) {_vm.start_date=$$v},expression:"start_date"}})],1)],1),_c('b-col',[_c('b-form-group',{attrs:{"label":"Fecha final"}},[_c('b-form-datepicker',{attrs:{"locale":"es","hide-header":true,"placeholder":"","name":"from","min":_vm.minDate,"max":_vm.maxDate},model:{value:(_vm.end_date),callback:function ($$v) {_vm.end_date=$$v},expression:"end_date"}})],1)],1)],1),_c('b-form-group',{attrs:{"label":"Colegios"}},[_c('v-select',{staticClass:"mx-select",attrs:{"options":_vm.schoolOptions,"reduce":function (item) { return item.id; },"placeholder":"Colegios","label":"name","clearable":false},model:{value:(_vm.school_id_pfrom),callback:function ($$v) {_vm.school_id_pfrom=$$v},expression:"school_id_pfrom"}})],1)],1)],1),_c('b-card-body',{staticClass:"statistics-body"},[_c('b-row',[_c('b-media',{attrs:{"no-body":""}},[_c('b-media-aside',{staticClass:"mr-2"},[_c('b-avatar',{attrs:{"size":"48","variant":'light-danger'}},[_c('feather-icon',{attrs:{"size":"24","icon":"TrendingUpIcon"}})],1)],1),_c('b-media-body',{staticClass:"my-auto"},[_c('h3',[_vm._v("Ventas")])])],1),_c('b-col',{staticClass:"d-flex justify-content-center"},[_c('b-button',{staticClass:"mr-1 f11",attrs:{"type":"button","variant":"primary"},on:{"click":function($event){return _vm.action(
                      'sales',
                      'pdf',
                      _vm.start_date,
                      _vm.end_date,
                      _vm.school_id_pfrom
                    )}}},[_vm._v(" PDF ")]),_c('b-button',{staticClass:"mr-1 f11",attrs:{"type":"button","variant":"outline-primary"},on:{"click":function($event){return _vm.action(
                      'sales',
                      'xlsx',
                      _vm.start_date,
                      _vm.end_date,
                      _vm.school_id_pfrom
                    )}}},[_vm._v(" EXCEL ")])],1)],1)],1)],1)],1)],1),_c('b-col',{attrs:{"xl":"6","md":"6"}},[_c('b-card',{staticClass:"mb-2"},[_c('b-form',{ref:"products"},[_c('b-row',[_c('b-col',[_c('b-row',[_c('b-col',{attrs:{"xl":"6","md":"12"}},[_c('b-form-group',{attrs:{"label":"Fecha inicial"}},[_c('b-form-datepicker',{attrs:{"locale":"es","hide-header":true,"placeholder":"","name":"from","min":_vm.minDate,"max":_vm.maxDate},model:{value:(_vm.start_date),callback:function ($$v) {_vm.start_date=$$v},expression:"start_date"}})],1)],1),_c('b-col',[_c('b-form-group',{attrs:{"label":"Fecha final"}},[_c('b-form-datepicker',{attrs:{"locale":"es","hide-header":true,"placeholder":"","name":"from","min":_vm.minDate,"max":_vm.maxDate},model:{value:(_vm.end_date),callback:function ($$v) {_vm.end_date=$$v},expression:"end_date"}})],1)],1)],1),_c('b-form-group',{attrs:{"label":"Colegios"}},[_c('v-select',{staticClass:"mx-select",attrs:{"options":_vm.schoolOptions,"reduce":function (item) { return item.id; },"placeholder":"Colegios","label":"name","clearable":false},model:{value:(_vm.school_id_pfrom),callback:function ($$v) {_vm.school_id_pfrom=$$v},expression:"school_id_pfrom"}})],1)],1)],1),_c('b-card-body',{staticClass:"statistics-body"},[_c('b-row',[_c('b-media',{attrs:{"no-body":""}},[_c('b-media-aside',{staticClass:"mr-2"},[_c('b-avatar',{attrs:{"size":"48","variant":'light-danger'}},[_c('feather-icon',{attrs:{"size":"24","icon":"TrendingUpIcon"}})],1)],1),_c('b-media-body',{staticClass:"my-auto"},[_c('h3',[_vm._v("Recargas")])])],1),_c('b-col',{staticClass:"d-flex justify-content-center"},[_c('b-button',{staticClass:"mr-1 f11",attrs:{"type":"button","variant":"primary"},on:{"click":function($event){return _vm.action(
                      'refills',
                      'pdf',
                      _vm.start_date,
                      _vm.end_date,
                      _vm.school_id_pfrom
                    )}}},[_vm._v(" PDF ")]),_c('b-button',{staticClass:"mr-1 f11",attrs:{"type":"button","variant":"outline-primary"},on:{"click":function($event){return _vm.action(
                      'refills',
                      'xlsx',
                      _vm.start_date,
                      _vm.end_date,
                      _vm.school_id_pfrom
                    )}}},[_vm._v(" EXCEL ")])],1)],1)],1)],1)],1)],1),_c('b-col',{attrs:{"xl":"6","md":"6"}},[_c('b-card',{staticClass:"mb-2"},[_c('b-form',{ref:"usersBalance"},[_c('b-row',[_c('b-col',[_c('v-select',{staticClass:"mx-select",attrs:{"options":_vm.schoolOptions,"reduce":function (item) { return item.id; },"placeholder":"Colegios","label":"name","clearable":false},model:{value:(_vm.school_id_pfrom),callback:function ($$v) {_vm.school_id_pfrom=$$v},expression:"school_id_pfrom"}})],1)],1),_c('b-card-body',{staticClass:"statistics-body"},[_c('b-media',{attrs:{"no-body":""}},[_c('b-media-aside',{staticClass:"mr-2"},[_c('b-avatar',{attrs:{"size":"48","variant":'light-danger'}},[_c('feather-icon',{attrs:{"size":"24","icon":"TrendingUpIcon"}})],1)],1),_c('b-media-body',{staticClass:"my-auto"},[_c('h3',[_vm._v("Saldos")])]),_c('b-col',[_c('b-button',{staticClass:"mr-1 f11",attrs:{"type":"button","variant":"outline-primary"},on:{"click":function($event){return _vm.action(
                      'users-balance',
                      'xlsx',
                      null,
                      null,
                      _vm.school_id_pfrom
                    )}}},[_vm._v(" EXCEL ")])],1)],1)],1)],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }